<template>
  <div class="container-stats">
    <div class="container-statistics">
      <ul id="breadcrumb" class="breadcrumb ProximaNovaRegular mx-4">
        <!--<li>
          <router-link to="/solutions/in/platform/models">
            <span>Model</span>
          </router-link>
        </li>-->
        <li>
          <router-link to="/solutions/ul/platform/allstats">
            <span>Model Results</span>
          </router-link>
        </li>
        <li>
          <router-link to="/solutions/ul/platform/attribution">
            <span>Attribution</span>
          </router-link>
        </li>
        <li class="ProximaNovaBold">
          Sale Statistics
        </li>
      </ul>
      <div class="d-flex justify-content-between align-items-center">
        <div class="title">Sales-Statistics</div>
      </div>

      <w-chart-overview :chartSummary="salseStatics"></w-chart-overview>
    </div>

    <div class="col-md-12 col-lg-12 col-sm-12 my-4 p-0">
      <div class="card pb-5 pb-lg-5 h-100">
        <div
          class="wrapper content-center px-3 pt-4"
          style="justify-content: space-between"
        >
          <h5 class="card-heading">Acid Test</h5>
        </div>
        <div class="row perf-card-wrapper">
          <div
            v-for="(data, index) in overallDetailsData"
            :key="index"
            class="col-12 col-md-4 d-flex justify-content-center"
          >
            <div class="perf-card">
              <Statistics
                :data="data"
                :index="index"
                :specialChar="false"
                :specialCharText="''"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="container-line-chart d-none">
      <div class="title">Actual vs Prediction</div>

      <ColumnChart :chartData="columnChartData" />
    </div>

    <div class="text-right my-5">
      <w-button
        :buttonText="'Progress'"
        class="my-5"
        :buttonLoader="'normal'"
        @buttonClicked="nextToTheInsights"
      >
      </w-button>
    </div>
    <page-loader v-if="count < 2"></page-loader>
  </div>
</template>
<script>
import ColumnChart from "@/components/Chart/ColumnChart.vue";
import Statistics from "@/components/Statistics.vue";
import PageLoader from "../../../widgets/PageLoader.vue";
// import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
import ChartOverview from "@/components/Summary/ChartOverview.vue";
import { AimoSolutionsServices } from "@/services/AimoSolutionsServices.js";
import Button from "@/components/Profile/Button.vue";
const aimoSolutionsServices = new AimoSolutionsServices();
export default {
  name: "Stats",
  components: {
    PageLoader,
    Statistics,
    ColumnChart,
    "w-button": Button,
    // "w-white-dropdown": WhiteDropdown,
    "w-chart-overview": ChartOverview,
  },
  data() {
    return {
      overallDetailsData: [
        { label: "Actual Sales", value: "1131.24 Cr" },
        { label: "Predicted Sales", value: "1091.8 Cr" },
        { label: "Error %", value: "3.48%" },
      ],
      count: 0,
      selectedstat: "",
      statsDropdown: [
        { text: "Elite", id: "elite" },
        { text: "Pro", id: "pro" },
        { text: "Lite Plan", id: "Lite Plan" },
      ],
      columnChartData: {
        data: [
          {
            name: "",
            data: [],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            dataLabels: {
                enabled: true
            }
          },
        },
        color: ["#18ffff", "#ffd740"],
        title: {
          text:
            "Investment vs ROI by Channel(Cr) from 01-SEPT-2021 to 15-NOV-2021",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {},
        legend: {
          enabled: false,
        },
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      salseStatics: [
        { name: "R2_Score", value: "0.86", className: "blue-pastel" },
        { name: "Actual_Mean", value: "21.05M", className: "green-pastel" },
        { name: "Predicted_Mean", value: "21.91M", className: "pink-pastel" },
        { name: "Actual_Std", value: "17.62M", className: "red-pastel" },
        { name: "Predicted_Std", value: "21.84M", className: "yellow-pastel" },
      ],
      classess: [
        "blue-pastel",
        "green-pastel",
        "pink-pastel",
        "red-pastel",
        "yellow-pastel",
        "purple-pastel",
        "green-pastel",
      ],
    };
  },
  methods: {
    getStaticsData() {
      this.salseStatics = [];
      aimoSolutionsServices
        .getChartData(
          "actual vs prediction",
          "/b2c/in/",
          "salesstatistics",
          "no"
        )
        .then((res) => {
          for (var i = 0; i < res.length; i++) {
            this.salseStatics.push({
              name: res[i].name,
              value: res[i].value,
              className: this.classess[i],
              image: res[i].name + ".svg",
            });
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    getChartData() {
      this.columnChartData.data[0].data = [];
      this.columnChartData.xAxis.categories = []
      aimoSolutionsServices
        .getChartData(
          "actual vs prediction",
          "/b2c/in/",
          "actualvsprediction",
          "no"
        )
        .then((res) => {
          for (var i = 0; i < res.length; i++) {
            this.columnChartData.data[0].data.push(res[i].value);
            this.columnChartData.xAxis.categories.push(res[i].name);
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    selectOption(e) {
      this.selectedstat = e;
    },
    nextToTheInsights() {
      this.$router.push("/solutions/ul/platform/insights");
    },
  },
  created() {
    this.getStaticsData();
    this.getChartData();
  },
};
</script>

<style lang="css" scoped>
.container-stats {
  width: 93%;
  margin: 71px auto 0 auto;
}

.container-statistics {
  padding-top: 1px;
}
.card-heading {
  margin: 20px 0px;
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}
.card {
  box-shadow: 0 0px 8px 0 #c9ced5;
  background-color: #fff;
}
.perf-card-wrapper {
  margin: 0px 24px;
}

.perf-card {
  width: 277px;
  height: 97px;
  box-shadow: 0 0 8px 0 #c9ced5;
  display: flex;
  align-items: center;
  padding-left: 21px;
  margin-top: 44px;
}

.title {
  font-family: ProximaNovaBold;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
  margin-top: 48px;
  margin-bottom: 32px;
}

.wrapper-statistics {
  box-shadow: 0px 0px 16px -13px rgb(0 0 0);
  border-radius: 4px;
  min-height: 126px;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0 2rem;
}

.item {
  display: flex;
  position: relative;
}

.item:not(:last-child):before {
  content: "";
  position: absolute;
  height: 40px;
  width: 1px;
  background: #e8edf5;
  right: 20px;
  top: 8px;
}

.item-img {
  background: #67e8ff;
  border-radius: 50%;
  padding: 2rem;
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.item-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
}

.item-text__title {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8394b4;
  padding-bottom: 1rem;
}

.item-text__subtitle {
  font-family: ProximaNovaBold;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
}

.btn-effect {
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}
</style>
